import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { ComponentPropsWithoutRef, FC } from 'react'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    padding: spacing(8, 20),
    [breakpoints.down('sm')]: {
      padding: spacing(4, 2),
    },
  },
}))

type SectionProps = ComponentPropsWithoutRef<'section'>

export const Section: FC<SectionProps> = ({ className, ...rest }) => {
  const classes = useStyles()

  return <div className={clsx(classes.root, className)} {...rest} />
}
