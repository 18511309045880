import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { PageCover } from '@/components/common/PageCover'
import { FormSection } from '@/components/resources/FormSection'
import { VerseSection } from '@/components/resources/VerseSection'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "prayer" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    prayer: strapiPrayer {
      contact_id
    }
  }
`

const PrayerPage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)
  return (
    <>
      <SEO title="Prayer" />
      <PageCover image={image} title="Prayer" />
      <VerseSection />
      <FormSection encryptedEmail={data.prayer.contact_id} />
    </>
  )
}

export default PrayerPage
