import { Input, InputLabel, makeStyles, Paper } from '@material-ui/core'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'

import { FormButton } from '@/components/common/FormButton'
import { useFormService } from '@/hooks/useFormService'

const ids = {
  form: 'prayer-form',
  name: 'prayer-form-name',
  email: 'prayer-form-email',
  message: 'prayer-form-message',
}

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: 460,
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
      borderRadius: 6,
      padding: spacing(3, 5, 4),
      [breakpoints.down('sm')]: {
        maxWidth: 360,
        width: '100%',
        padding: spacing(3),
      },
    },
    title: {
      marginBottom: spacing(6),
      color: palette.primary.main,
      fontSize: '3rem',
      lineHeight: 1.1875,
      [breakpoints.down('md')]: {
        fontSize: '2rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
    fields: {
      alignSelf: 'stretch',
    },
    field: {
      marginBottom: spacing(6),
      [breakpoints.down('sm')]: {
        marginBottom: spacing(4),
      },
    },
    optional: {
      fontWeight: typography.fontWeightRegular,
    },
  })
)

type FormInputs = {
  name: string
  email: string
  message: string
}
type PrayerFormProps = {
  encryptedEmail: string
}

export const PrayerForm: FC<PrayerFormProps> = ({ encryptedEmail }) => {
  const classes = useStyles()
  const { pending, submit } = useFormService()
  const { register, handleSubmit, reset } = useForm<FormInputs>()
  const { ref: nameRef, ...nameRest } = register('name', { required: true })
  const { ref: emailRef, ...emailRest } = register('email')
  const { ref: messageRef, ...messageRest } = register('message', {
    required: true,
    minLength: 10,
  })

  const onSubmit = async (data: FormInputs) => {
    const payload = {
      visitor_name: data.name,
      visitor_email: data.email.length > 0 ? data.email : undefined,
      message: data.message,
      recipient_name: 'Prayer IBCB',
      recipient_email: encryptedEmail,
      email_type: 'prayer-request',
    }

    await submit(payload, reset)
  }

  return (
    <Paper
      component="form"
      id={ids.form}
      className={classes.root}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={classes.title}>How can we pray for you?</div>
      <div className={classes.fields}>
        <div className={classes.field}>
          <InputLabel htmlFor={ids.name}>Name</InputLabel>
          <Input
            id={ids.name}
            type="text"
            placeholder="Your name"
            required
            inputRef={nameRef}
            {...nameRest}
          />
        </div>
        <div className={classes.field}>
          <InputLabel htmlFor={ids.email}>
            Email&nbsp;
            <em className={classes.optional}>(optional)</em>
          </InputLabel>
          <Input
            id={ids.email}
            type="email"
            placeholder="valid-email@address.com"
            inputRef={emailRef}
            {...emailRest}
          />
        </div>
        <div className={classes.field}>
          <InputLabel htmlFor={ids.message}>Prayer Request</InputLabel>
          <Input
            id={ids.message}
            placeholder="How can we pray for you?"
            inputProps={{
              minLength: 10,
            }}
            rows={3}
            multiline
            required
            inputRef={messageRef}
            {...messageRest}
          />
        </div>
      </div>
      <FormButton pending={pending}>Submit</FormButton>
    </Paper>
  )
}
