import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import { PageContainer } from '@/components/common/PageContainer'

import { PrayerForm } from './PrayerForm'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  text: {
    margin: spacing(0, 15, 0, 0),
    fontSize: '1.5rem',
    lineHeight: 1.417,
    letterSpacing: '0.020833em',
    [breakpoints.down('md')]: {
      margin: spacing(0, 0, 8, 0),
      fontSize: '1.25rem',
    },
    [breakpoints.down('sm')]: {
      margin: spacing(0, 0, 4, 0),
      fontSize: '0.875rem',
    },
  },
}))

type FormSectionProps = {
  encryptedEmail: string
}

export const FormSection: FC<FormSectionProps> = ({ encryptedEmail }) => {
  const classes = useStyles()

  return (
    <PageContainer>
      <div className={classes.row}>
        <p className={classes.text}>
          At IBC Berlin, we believe that prayer is not only essential for our
          spiritual growth but also one way that we care for others. We have
          experienced prayer as an effective means of facing impossible
          situations, a powerful way to seek God’s presence and God’s help.
          <br />
          <br />
          So whatever your situation or circumstance, why not ask for prayer?
          God hears you as well, but it is good to know you have other people
          who care, joining with you!
          <br />
          <br />
          Submit your prayer request and it will be forwarded immediately to our
          prayer team who will pause to pray for you now and during our
          regularly scheduled prayer times.
        </p>
        <PrayerForm encryptedEmail={encryptedEmail} />
      </div>
    </PageContainer>
  )
}
