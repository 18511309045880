import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'

type PageCoverProps = {
  title: string
  image: IGatsbyImageData
  className?: string
}

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
    color: palette.text.primary,
    [breakpoints.down('md')]: {
      height: 300,
    },
    [breakpoints.down('sm')]: {
      height: 200,
    },
  },
  image: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  title: {
    zIndex: 100,
    fontSize: '3.5rem',
    fontWeight: typography.fontWeightBold,
    letterSpacing: '0.010357em',
    textAlign: 'center',
    color: 'inherit',
    textShadow: `0 0 3px ${palette.primary.contrastText}`,
    [breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
}))

export const PageCover: FC<PageCoverProps> = ({
  title,
  image,
  className,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <GatsbyImage
        image={image}
        alt=""
        className={classes.image}
        style={{ position: 'absolute' }}
      />
      <div className={classes.title}>{title}</div>
    </div>
  )
}
